<template>
  <div class="contact-page">
    <el-form>
      <el-form-item label="地址"  v-if="companyInfo.address">
        <span>{{companyInfo.address}}</span>
      </el-form-item>
      <el-form-item label="电话"  v-if="companyInfo.telephone">
        <span>{{companyInfo.telephone}}</span>
      </el-form-item>
      <el-form-item label="邮箱"  v-if="companyInfo.email">
        <span>{{companyInfo.email}}</span>
      </el-form-item>
      <el-form-item label="微信"  v-if="companyInfo.wx">
        <span>{{companyInfo.wx}}</span>
      </el-form-item>
      <el-form-item label="QQ"  v-if="companyInfo.qq">
        <span>{{companyInfo.qq}}</span>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        companyInfo: {}
      }
    },
    mounted() {
      this.getCompanyInfo()
    },
    methods: {
      getCompanyInfo() {
        axios.get('/api/company').then(data => {
          this.companyInfo = data.data
        })
      }
    }
  }
</script>
<style lang="less">
  .contact-page {
    width: 50%;
    margin: 16px auto;
    
    .el-form-item__label {
      font-size: 20px;
    }
    
    .el-form-item__content {
      font-size: 20px;
    }
  }
</style>
